<template>
  <module
    ref="module"
    id="createTicket"
    title-icon="fa fa-cog"
    :title="$t('support')"
    :use-default-list="false"
  >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
      <!-- Content -->
      <div v-else class="row">
      <div class="col-md-6">
        <div>
          <div class="card-header">
            <h4 class="card-title">{{ submittype }}</h4>
          </div>
          <div class="card-body">

            <div class="form-group" v-if="error">
              <div class="col-sm-12">
                <div :class="`alert-${alerttype}`" class="alert alert-dismissible fade show">
                  <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                          v-on:click="closeAlert">
                    <i class="nc-icon nc-simple-remove"></i>
                  </button>
                  <span>{{ error_msg }}</span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-12">
                <el-select class="select" style="width: 100%;"
                           size="large" v-model="form.type" @change="typechange">
                  <el-option v-for="option in types" class="select"
                             :value="option.code" :label="option.name" :key="option.code">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <fg-input
                  v-model="form.subject" v-validate="modelValidation.subject"
                  :error="getError('subject')" :placeholder="subjectTxt" type="email" :name="subject"></fg-input>
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-12">
                <textarea v-model="form.description" id="description" class="form-control" :placeholder="description"
                          rows="5"></textarea>
                <div class="text-danger invalid-feedback" style="display: block;" v-if="errordesc">
                  {{ $t('descneeded') }}
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-md-6">
                <vue-hcaptcha sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
                              @verify="verify"
                              ref="recaptcha">
                </vue-hcaptcha>
              </div>
              <div class="col-md-3" style="margin: auto">
                <div v-if="inprogress">
                  <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
                </div>
                <p-button
                  nativeType="submit"
                  @click.prevent="validate"
                  slot="footer"
                  type="info"
                  block
                  v-if="inprogress == false">
                  {{ $t('submit') }}
                </p-button>
              </div>

            </div>
          </div>
        </div>
      </div>  <!-- end card -->
      <div class="col-sm-6">
        <div>
          <div class="card-header">
            <h4 class="card-title">{{ $t('contactus') }}</h4>
          </div>
          <div class="card-body">
            <div class="card-body-content">
              <div class="card-body-telegram">
                <p-button type="info" v-on:click="goTelegram()" style="margin: 10px;">
                  <i class="fa fa-telegram fa-4x"></i>
                </p-button>
              </div>
              <div class="card-body-telegram-txt">
                <span class="card-text">{{ $t('contactTelegramTxt') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col-md-6 -->
    </div>
    </div>
  </module>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { Select, Option } from 'element-ui'
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  const timeoutcount = 8000;

  export default {
    components: {
      Card,
      [Option.name]: Option,
      [Select.name]: Select,
      VueHcaptcha,
    },
    data() {
      return {
        form: {
          subject: '',
          description: '',
          type: {
            name: this.$t('support'),
            code: 'Support'
          }
        },
        modelValidation: {
          subject: {
            required: true,
            min: 1
          }
        },
        captcha_token: true,
        submittype: this.$t('submit_support'),
        inprogresstxt: this.$t('loadingtxt'),
        description: this.$t('ticketdescription_support'),
        subjectTxt: this.$t('ticketsubject_support'),
        inprogress: false,
        subject: this.$t('subject'),
        errordesc: false,
        error: false,
        error_msg: null,
        alerttype: 'danger',
        types: [
          {
            name: this.$t('feedback'),
            code: 'Feedback'
          },
          {
            name: this.$t('suggestion'),
            code: 'Suggestion'
          },
          {
            name: this.$t('support'),
            code: 'Support'
          }
        ]
      }
    },
    methods: {
      verify(resp) {
        this.captcha_token = resp;
      },
      closeAlert () {
        this.error = false;
      },
      closeSuccessAlert () {
        this.error = false;
        this.form.subject = '';
        this.form.description = '';
      },
      getError (fieldName) {
        fieldName = this.$t(fieldName);
        return this.errors.first(fieldName);
      },
      typechange () {
        if (this.form.type.name == 'Feedback') {
          this.submittype = this.$t('submit_feedback');
          this.description = this.$t('ticketdescription_feedback');
          this.subjectTxt = this.$t('ticketsubject_feedback');
        }
        if (this.form.type.name == 'Support') {
          this.submittype = this.$t('submit_support');
          this.description = this.$t('ticketdescription_support');
          this.subjectTxt = this.$t('ticketsubject_support');
        }
        if (this.form.type.name == 'Suggestion') {
          this.submittype = this.$t('submit_suggestion');
          this.description = this.$t('ticketdescription_suggestion');
          this.subjectTxt = this.$t('ticketsubject_suggestion');
        }
      },
      validate () {
        this.error = false;
        this.errordesc = false;

        const ticket_created = oklookup => {
          this.inprogress = false;
          this.$toast.success(this.$t('ticketcreated'));
          setTimeout(this.closeSuccessAlert, timeoutcount);
        };
        // Callback when user fails registration
        const ticket_failed = error => {
          this.inprogress = false;
          this.error = error.data.error;
          this.alerttype = 'danger';
          if (error.data.msg !== undefined && error.data.msg !== null) {
            this.error_msg = error.data.msg;
          } else {
            this.error_msg = 'ticketfailedcreation';
          }
          this.$toast.warning(this.$t(this.error_msg))
        };

        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            if (this.form.subject !== '' && this.form.description !== '') {
              this.inprogress = true;
              const ticket = {
                "userid": user_data['email'],
                "name": user_data['user_metadata']['first_name'] +" "+ user_data['user_metadata']['last_name'],
                "subject": this.form.subject,
                "type": this.form.type.code,
                "text64": btoa(this.form.description),
                "captcha_token": this.captcha_token,
              }
              this.$createTicket(ticket).then(ticket_created, ticket_failed);
            }
            if (this.form.description === '') {
              this.errordesc = true;
            }
          }
        });
      },
      goTelegram() {
        window.open("https://t.me/HokoSupport", "_blank");
      },
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    }
  }
</script>
<style scoped>
  #description {
    max-height: 125px;
  }


.card-text {
  text-align: justify;
  display: inline-grid;
  margin-left: 80px;
  margin-right: 0;
}

.card-body-telegram {
  margin-top: 10px;
}

</style>
